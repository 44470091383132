.select-tag {
    user-select: none;
}
.card-index {
    position: absolute;
    right: 8px;
    top: 8px;
}
.card-box {
    position: relative;
}
.card-index {
    color: #272e3b;
}

body.dark-mode .card-index {
    color: rgba(255,255,255, 0.6)
}