:root {
  --width-small: 500px;
  --width-medium: 700px;
  --width-large: 1060px;
}
@media (max-width: 500px) {
  .main {
    padding-top: 30px;
  }
  .github-link-box {
    right: 11px;
    bottom: 10px;
  }
  .github-link-box svg {
    width: 22px;
    height: 22px;
  }
  .theme-switch-box {
    right: 10px;
    bottom: 40px;
  }
    .theme-switch-box.hide-github {
    bottom: 10px;
   }
  .theme-switch-box svg {
    width: 24px;
    height: 24px;
  }
  .select-tag {
    font-size: 13px;
    padding: 2px 6px;
    height: 24.5px;
    min-width: 62px;
    line-height: 18px;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  .tag-selector-wrapper {
    margin-right: -6px;
  }
  input:hover {
    background-color: #e5e6eb;
    border: 1px solid #e5e6eb;
    cursor: pointer;
  }
  input:focus,
  input:focus-visible {
    background-color: #ffffff;
    border: 1px solid #272e3b;
    color: #272e3b;
  }
  input {
    height: 30px;
    font-size: 16px;
    padding: 0 16px;
    border-radius: 4px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    outline: none;
    color: #8a92a6;
  }
  .search {
    margin-bottom: 14px;
  }
  .tag-selector {
    margin-bottom: 12px;
  }
  .content {
    display: grid;
    grid-gap: 0 4px;
    margin: 0 2vw;
    grid-template-columns: repeat(3, 1fr);
  }

  .card-index {
    display: none;
  }
  .card-box {

    max-width: 200px;
    display: flex;
    margin-bottom: 10vw;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: box-shadow, transform 0.3s;
    border-radius: 4px;
    padding: 0;
    background-color: #ffffff;
    text-decoration: none;
  }
  .card-content {
    flex: 1;
    overflow: hidden;
    width: 100%;
    display: block;
  }
  .card-right-top {
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0px;
    height: 36px;
    justify-content: center;
  }
  .card-right-bottom {
    display: none;
  }
  .card-tag {
    display: none;
  }
  .card-left {
    margin-right: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .card-right-title {
    display: inline-block;
    color: #272e3b;
    font-weight: 500;
    font-size: 12px;
    margin-top: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
  .card-left > img {
    width: 32px;
    height: 32px;
  }
  .card-left embed {
    width: 32px;
    height: 32px;
  }
  .card-right {
    margin-right: 0px;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .main {
    padding-top: 40px;
  }
  .github-link-box {
    right: 15px;
    bottom: 14px;
  }
  .github-link-box svg {
    width: 27px;
    height: 27px;
  }
  .theme-switch-box {
    right: 14px;
    bottom: 46px;
  }
  .theme-switch-box.hide-github {
    bottom: 15px;
   }
  .theme-switch-box svg {
    width: 30px;
    height: 30px;
  }
  .select-tag {
    font-size: 13px;
    padding: 2px 8px;
    min-width: 70px;
    line-height: 20px;
    height: 27px;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  .tag-selector-wrapper {
    margin-right: -6px;
  }
  input:hover {
    background-color: #e5e6eb;
    border: 1px solid #e5e6eb;
    cursor: pointer;
  }
  input:focus-visible,
  input:focus {
    background-color: #ffffff;
    border: 1px solid #272e3b;
    color: #272e3b;
  }
  input {
    height: 36px;
    font-size: 16px;
    padding: 0 20px;
    border-radius: 4px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    outline: none;
    color: #8a92a6;
  }
  .search {
    margin-bottom: 18px;
  }
  .tag-selector {
    margin-bottom: 14px;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 4px;
    justify-content: center;
    margin: 0 5vw;
  }
  .card-index {
    display: none;
  }
  .card-box {
    max-width: 200px;
    margin-bottom: 10vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: box-shadow, transform 0.3s;
    border-radius: 4px;
    padding: 0;
    background-color: #ffffff;
    text-decoration: none;
  }
  .card-content {
    flex: 1;
    overflow: hidden;
    width: 100%;
    display: block;
  }
  .card-right-top {
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0px;
    height: 36px;
    justify-content: center;
  }
  .card-right-bottom {
    display: none;
  }
  .card-tag {
    display: none;
  }
  .card-left {
    margin-right: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .card-right-title {
    display: inline-block;
    color: #272e3b;
    font-weight: 500;
    font-size: 14px;
    margin-top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
  .card-left > img {
    width: 36px;
    height: 36px;
  }
  .card-left embed {
    width: 36px;
    height: 36px;
  }
  .card-right {
    margin-right: 0px;
  }
}
@media (max-width: 1060px) and (min-width: 700px) {
  .main {
    padding-top: 60px;
  }
  .github-link-box {
    right: 11px;
    bottom: 14px;
    
  }
  .github-link-box svg {
    width: 27px;
    height: 27px;
  }
  .theme-switch-box {
    right: 10px;
    bottom: 50px;
  }
  .theme-switch-box.hide-github {
    bottom: 20px;
   }
  .theme-switch-box svg {
    width: 30px;
    height: 30px;
  }
  .select-tag {
    font-size: 13px;
    padding: 2px 10px;
    min-width: 74px;
    line-height: 20px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .tag-selector-wrapper {
    margin-right: -10px;
  }
  input:hover {
    background-color: #e5e6eb;
    border: 1px solid #e5e6eb;
    cursor: pointer;
  }
  input:focus-visible,input:focus {
    background-color: #ffffff;
    border: 1px solid #272e3b;
    color: #272e3b;
  }
  input {
    height: 40px;
    font-size: 16px;
    padding: 0 22px;
    border-radius: 4px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    outline: none;
    color: #8a92a6;
  }
  .tag-selector {
    margin-bottom: 16px;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    justify-content: center;
    margin: 0 5vw;
  }
  .card-index {
    display: none;
  }
  .card-box {
    max-width: 299.67px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: box-shadow, transform 0.3s;
    border-radius: 4px;
    padding: 9%;
    background-color: #fafbfc;
    text-decoration: none;
  }
  .card-box:hover {
    transform: translateY(-6px);
      box-shadow: 6px 6px 12px 0px #eceef4;
  }
  .card-content {
    flex: 1;
    overflow: hidden;
    width: 100%;
    display: flex;
  }
  .card-right-top {
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0px;
    height: 42px;
    justify-content: left;
  }
  .card-right-top span {
    display: block;
    width: 100%;
  }
  .card-right-bottom {
    display: none;
  }
  .card-tag {
    color: #878a95;
    font-size: 13px;
    background-color: #fafbfc;
    display: inline-block;
    box-sizing: border-box;
    padding: 0x;
    line-height: 20px;
    border-radius: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .card-left {
    margin-right: 5%;
    align-items: center;
    display: flex;
  }
  .card-right-title {
    display: inline-block;
    color: #272e3b;
    font-size: 14px;
    margin-right: 7px;
    overflow: hidden;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .card-left > img {
    width: 40px;
    height: 40px;
  }
  .card-left embed {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1060px) {
  .main {
    padding-top: 80px;
  }
  .github-link-box {
    right: 12px;
    bottom: 20px;
    
  }
  .github-link-box svg {
    width: 35px;
    height: 35px;
  }
  .theme-switch-box {
    right: 10px;
    bottom: 70px;
  }
   .theme-switch-box.hide-github {
    bottom: 20px;
   }

  .theme-switch-box svg {
    width: 40px;
    height: 40px;
  }
  .select-tag {
    font-size: 14px;
    padding: 2px 10px;
    min-width: 85px;
    line-height: 20px;
    height: 28px;
    border-radius: 16px;
    display: inline-block;
    text-align: center;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .tag-selector-wrapper {
    margin-right: -16px;
  }
  input:hover {
    background-color: #e5e6eb;
      border: 1px solid #e5e6eb;
      cursor: pointer;
  }
  input:focus,input:focus-visible {
    background-color: #ffffff;
    border: 1px solid #272e3b;
    color: #272e3b;
  }
  input {
    height: 40px;
    font-size: 16px;
    padding: 0 32px;
    border-radius: 4px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    outline: none;
    color: #8a92a6;
   
  }
  .content {
    display: grid;
    grid-template-columns: repeat(3, minmax(299.67px, 350px));
    grid-gap: 20px;
    justify-content: center;
    margin: 0 5vw;
  }

  .tag-selector {
    margin-bottom: 20px;
  }
  .card-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: box-shadow, transform 0.3s;
    border-radius: 4px;
    padding: 9%;
    background-color: #fafbfc;
    text-decoration: none;
    max-width: 350px;
   
  }
  .card-box:hover {
    transform: translateY(-6px);
      box-shadow: 6px 6px 12px 0px #eceef4;
  }
  .card-content {
    flex: 1;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .card-right-top {
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
    width: 100%;
  }
  .card-right-bottom {
    color: #86909c;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  .card-tag {
    color: #878a95;
    font-size: 12px;
    background-color: #f2f3f5;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 8px;
    border-radius: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 68px;
  }
  .card-left {
    margin-right: 5%;
    align-items: center;
    display: flex;
  }
  .card-right-title {
    display: inline-block;
    color: #272e3b;
    font-size: 14px;
    font-weight: 500;
    margin-right: 7px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .card-right {
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    height: 48px;
    flex: 1;
    overflow: hidden;
  }
  .card-left > img {
    width: 48px;
    height: 48px;
  }
  .card-left embed {
    width: 48px;
    height: 48px;
  }
}
.content-wraper::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.content-wraper {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* firefox */
  height: 100%;
}
.cards {
  padding-top: 10px;
  padding-bottom: 250px;
}
.record-wraper {
  text-align: center;
  position: fixed;
  bottom: 1vh;
  left: 0;
  right: 0;
}

.record-wraper>a {
  text-decoration: none;
  color: #bbb;
}
